body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E5E5E5 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bottom--2 {
  top: 8px !important;
}

.bg-giftano {
  background-image: url("./assets/img/theme/thems.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.col-game-des {
  background-color: rgba(46, 46, 46, 0.5);
  border: 1px solid #1a1a1a;
  border-radius: 5px;
}

.col-game-primary {
  background-color: rgba(94, 114, 228, 0.4) !important;
  border: 1px solid #006d64;
  border-radius: 5px;
}

.mt--400 {
  margin-top: -400px !important;
}

.mt--500 {
  margin-top: -500px !important;
}

/* 
.secret-supper.headroom--not-top {
  background-color: #fb6340 !important;
} */

.secret-dinner.headroom--not-top {
  /* background-color: #fb6340 !important; */
  background-color: #019082 !important;
}

.secret-santa.headroom--not-top {
  /* background-color: #5D66D1 !important; */
  background-color: #019082 !important;
}

.secret-supper.headroom--not-top {
  background-color: #019082 !important;
}

.secret-lunch.headroom--not-top {
  /* background: #281483 !important; */
  background-color: #019082 !important;
}

/* section > .secret-supper{
  background: linear-gradient(150deg, #7795f8 15%, #6772e5 70%, #555abf 94%); 
}

section > .secret-santa {
  background: linear-gradient(150deg, #7795f8 15%, #6772e5 70%, #555abf 94%) !important; 
}
section > .secret-dinner {
  background: linear-gradient(150deg, #cf3513 15%, #fc6442 70%, #ff7c5e 94%) !important;

}

section > .secret-lunch {
  background: linear-gradient(150deg, #281483 15%, #8f6ed5 70%, #d782d9 94%) !important; 
} */

.section-shaped .shape-primary :nth-child(1) {
  background: #53f;
}

.section-shaped .shape-primary :nth-child(2) {
  background: #4553ff;
}

.section-shaped .shape-primary :nth-child(3) {
  background: #4f40ff;
}

.section-shaped .shape-primary :nth-child(4) {
  background: #25ddf5;
}

.section-shaped .shape-primary :nth-child(5) {
  background: #1fa2ff;
}

.react-datepicker-wrapper {
  width: 85% !important;
}

input.w-lg-25:has(div) {
  background: #000 !important;
}

.react-datepicker-popper {
  width: 328px;
}

.link-tos,
.link-tos:visited {
  color: #02e2ff;
}

.form-group-agreement {
  padding: 15px 10px;
  display: block;
}

.form-group-agreement>input[type='checkbox'] {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  transform: scale(1.5);
}

.table-res {
  width: 100% !important;
  background: #fff !important;
  padding: 10px 10px !important;
}

.table-res>thead>tr>th:nth-child(2) {
  width: 50% !important;
}

.react-datepicker__input-container>input {
  /* border: none !important; */
  border-radius: 0px 2px 2px 0px !important;
}

.dropdown-button {
  box-shadow: none !important;
  text-align: left !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

/* .content-gamge {
  background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
} */
h2 {
  opacity: 1 !important;
}

li.list-group-item.border-0.text-justify {
  background: transparent !important;
}

.border-0{
  border: none !important;
}

.text-capitelize {
  text-transform: capitalize !important;

}
.header-game {
  height: 300px !important;
  padding: 40px 30px !important;
  border-radius: 20px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
}
.header-game-1{
  max-height: 350px;
  border-radius: 5px;
  padding: 0px !important;
  background-size: cover;
  transform: perspective(30px) rotateX(-0.5deg) skew(2deg);
  width: 95% !important;
  margin: auto;
  margin-top: 5px;
}
.caption{
  transform: perspective(70px) rotateX(2deg) skew(-4deg) !important
}

.content-gamge.custome {
  height: 300px !important;
  border-radius: 15px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 91%;
  height: 100%;
  transform: perspective(30px) rotateX(-0.5deg) skew(2deg);
  margin: auto;
  margin-top: 10px;
  background-size: cover !important;
}

.content-gamge h6 {
  margin-bottom: 2px !important;
  font-size: 1.1rem;
  margin-bottom: 5px !important;
  line-height: 1.5rem !important;
}


span.btn-d {
  /* display:"block", fontSize: 9, borderTop:"1 solid #ffff", paddingTop: 2, marginTop: 2 */
  display: none;
  font-size: 9px;
  border-top: 1px solid #ffffff;
  padding-top: 2px;
  margin-top: 2px;

}

input:disabled {
  background-color: #f4f5f7 !important;
}

textarea:disabled {
  background-color: #f4f5f7 !important;
}

/* layout phase 1 */
#linkRule:hover {
  cursor: pointer;
}

input.input-giftano-grup {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;

  border: none !important;

}

.form-control {
  font-size: 16px !important;

}

.input-giftano-grup-text {

  border-top-left-radius: 10px !important;
  border: none !important;
  border-bottom-left-radius: 10px !important;
}

textarea {
  border-top-right-radius: 10px !important;
  /* border: none !important; */
  border-bottom-right-radius: 10px !important;
  font-size: 16px !important;
}

.btn-delete-participant {
  box-shadow: none !important;
  border: none !important;
}

#btnMoreParticipant {
  width: 35%;
  border-radius: 20px;
  padding: 10px 10px;
  border-color: #F2994A;
  color: #F2994A;
}

.text-warning {
  color: #F2994A;

}

.bg-secondary {
  background-color: #E5E5E5 !important;
}

.input-group-alternative {
  border-radius: 10px !important;
  box-shadow: none !important;
}

#titlleHeader {
  font-family: Permanent Marker;
  color: #02AFA0 !important;
  line-height: 120%;
  font-size: 48px;
  font-style: normal;
  font-weight: normal;
}

#titlleHeader_2 {
  font-family: Permanent Marker;
  color: #02AFA0 !important;
}

#buttonSubmitGame {
  background: linear-gradient(172.53deg, #9245F3 14.57%, #B47AFD 89.35%);
  border-radius: 36px;
  color: white;
  border: #9245F3;
}

#gameWelcome>h5 {
  width: 300%;
  text-align: left;
  display: flex;
  vertical-align: middle;
  margin: 0;
  flex-grow: 1;
  padding: 0;
  font-size: 26px;
  font-weight: 500;
  line-height: 2rem;

}

#gameWelcome>p {
  flex-grow: 2;
  padding: 0px 10px 0px 10px;
  line-height: 1.3rem;
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
}

/* carousel */
.carousel-control-prev-icon {
  background-color: #02AFA0 !important;
  padding: 10px !important;
  border-radius: 50% !important;
  background-size: 50% !important;
}

.carousel-control-next-icon {
  background-color: #02AFA0 !important;
  padding: 10px !important;
  border-radius: 50% !important;
  background-size: 50% !important;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
}

.carousel-control-prev {
  left: -70px !important;
}

.carousel-control-next {
  right: -80px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 25px !important;
  background-position: center;
  height: 25px !important;
  background: no-repeat 50% / 100% 100%;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 10px !important;
  height: 10px !important;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  border-radius: 50%;
  background-color: rgb(73, 73, 73) !important;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

.carousel-giftano {
  min-height: 350px;
}

.carousel-giftano>div.carousel-image {
  width: 30%;
}

.carousel-giftano>div {
  width: 70%;
}

.carousel-indicators {
  bottom: -50px !important;
}

.carousel-indicators li.active {
  opacity: 1;
  background-color: #02AFA0 !important;
}

.shape {
  background-size: 100% 300px !important;
  background-repeat: no-repeat !important;
}

.w-90 {
  width: 91%;
  margin: auto !important;
}

.bg-gradient-primary {
  background: linear-gradient(149.26deg, #9245F3 14.57%, #B47AFD 89.35%) !important;

}

.game-icon {
  transform: perspective(30px) rotateX(7deg) skew(-5deg) !important;
}

.col-button {
  position: relative;
  top: -35px;
  width: 85%;
}

.btn.bg-gradient-primary {
  background: linear-gradient(149.26deg, #9245F3 14.57%, #B47AFD 89.35%);

  border: #9245F3;
}

.btn.bg-gradient-warning, .btn.bg-gradient-success, .btn.bg-gradient-primary, .btn.bg-gradient-warning:hover, .btn.bg-gradient-success:hover, .btn.bg-gradient-primary:hover  {
  border-radius: 36px;
  color: white;
}

.step__title {
  position: relative;
  background-color: #F2994A;
  height: 30px;
  width: 20%;
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 14px;
  font-family: sans-serif;
}
.step__title:before,
.step__title:after {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.step__title:after {
  border-right: 20px solid #fff;
  right: 0px;
}

.image-section{
  width: 100%;
  height: 100px;
  background-size: cover !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

p.testimoni{
  font-size: 14px;
  font-weight: 400;
}

.w-30{
  width: 30%;
}

h6.person-testimoni{
  font-weight: 600;
  font-size: 13px;
  font-style: italic;
}

div.testimoni > div.media-body{
  background: #F8F8F8;
  padding: 10px !important;
}
div.testimoni{
  background: #F8F8F8;

}

.col-image-testimoni{
  border-top-left-radius: 10px;
}
.col-profile{
  border-radius:5px;
}

.border-2{
  border-radius:5px;

}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  background-color: #F4F5F7 !important;
  display: block;
}
