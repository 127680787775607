
@media only screen and (max-width: 992px) {
    .content-gamge {
        height: 200px !important;
        padding: 30px 30px !important;
    }
  
    .content-gamge h1 {
        font-size: 2rem;
    }
  }

  @media only screen and (max-width: 600px) {
      
    .d-md-block {
        display: none !important;
    }
    #btnMoreParticipant {
        width: 100%;
    }
  
    #startGameSideBanner {
        display: none;
    }
  
    .react-datepicker-wrapper {
        width: 86.5% !important;
    }
  
    .table-res {
        width: 100% !important;
    }
  
    .table-res>thead>tr>th:nth-child(2) {
        width: 50% !important;
    }
  
    .d-md-block {
        display: block !important;
        /* background: #000000; */
    }
    .shape {
        background-size: 100% 250px !important;
        background-repeat: no-repeat !important;
    }
  }

    
  @media only screen and (max-width: 576px) {

    .header-game-1{
        height: 200px !important;
    }
    .caption {
        transform: none !important;
    }
    .caption > div > .header-game > h1{
        font-size: 18px;
    }
    .react-datepicker-wrapper {
        width: 86% !important;
    }
  
    .d-md-block {
        display: none !important;
    }
    .content-gamge {
        height: 100px !important;
        padding: 0px 10px !important;
    }
  
    .content-gamge h1 {
        font-size: 1.5rem;
        margin-bottom: 0px !important;
    }
  
    .content-gamge h6 {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 1px !important;
    }
  }
 
    
  @media only screen and (max-width: 540px) {
    .content-gamge.custome{
        height: 200px !important;
    }
    .header-game-1{
        height: 200px !important;
    }
    .caption {
        transform: none !important;
    }
    .caption > div > .header-game > h1{
        font-size: 18px;
    }
    .react-datepicker-wrapper {
        width: 86% !important;
    }
  
      .table-participant-game{
          width: 100% !important;
          overflow: auto;
      }
    .react-datepicker-wrapper {
        width: 86% !important;
    }
  
    .py-5 {
        padding: 0 !important;
    }
  
    .d-md-block {
        display: none !important;
    }
  
    .table-res {
        width: 130% !important;
    }
  
    .table-res>thead>tr>th:nth-child(2) {
        width: 50% !important;
    }

    .website-des > div > div {
        display: flex;
        justify-items: center;
        justify-content: center;
    }
    .shape {
        background-size: 100% 300px !important;
        background-repeat: no-repeat !important;
    }
  }

   
  @media only screen and (max-width: 411px) {

    .content-gamge.custome{
        height: 200px !important;
    }
    .header-game-1{
        height: 200px !important;
    }
    .caption {
        transform: none !important;
    }
    .caption > div > .header-game > h1{
        font-size: 18px;
    }
    .react-datepicker-wrapper {
        width: 86% !important;
    }
  
    .py-5 {
        padding: 0 !important;
    }
  
    .d-md-block {
        display: none !important;
    }
  
    .table-res {
        width: 150% !important;
    }
  
    .table-res>thead>tr>th:nth-child(2) {
        width: 50% !important;
    }

    .website-des > div > div > h2{
        font-size: 24px;
    }
    .game-detail-shape.shape {
        background-size: 100% 400px !important;
        background-repeat: no-repeat !important;
    }
    .shape {
        background-size: 100% 250px !important;
        background-repeat: no-repeat !important;
    }

    #gameWelcome{
        display: block !important;
    }
    #gameWelcome > h5{
        padding: 10px !important;
        width: 100%;
        text-align: center;
    }
    .step__title {
        position: relative;
        background-color: #F2994A;
        height: 30px;
        width: 50%;
        display: grid;
        place-items: center;
        color: #fff;
        font-size: 14px;
        font-family: sans-serif;
    }

    .step__title:after {
        border-right: 20px solid #fff;
        right: -2px;
    }
    .carousel-giftano>div.carousel-image {
        width: 30%;
        display: none !important;
    }

    .carousel-giftano>div {
        width: 100%;
    }
    #buttonSubmitGame{
        width: 100% !important;
    }
    .content-gamge {
        height: 150px !important;
        padding: 0px 10px !important;
    }
    .col-button{
        text-align: center !important;
    }
    .section {
        position: relative;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .col-lg-1{
        padding-left: 15px;

    }
    .btn-delete-participant{
        border: 1px solid #F4F5F7 !important;
        display: block;
        width: 100% !important;
        /* width: 15%; */
        top: 2.5px !important;
        /* border: 1px solid #000 !important; */
    }
    .col-button-deleted{
        padding-left: 15px !important;
    }
    .btn-delete-participant{
        padding: 10px;
        background:#e74c3c !important;
        color:white !important;
    }
    .btn-delete-participant > i{
        color: white !important;
    }
    .form-game{
        width: 100% !important;
        padding: 0 !important;
    }
    #titlleHeader{
        color: white !important;
    }
    #titlleHeader.nowhite{
        color:#02AFA0 !important;
    }
    .col-participan-email{
        /* border: 2px solid #000; */
        padding-right: 0 !important;
        width: 80%;
    }
    .col-sm-1{
        width: 20%;
    }
  }


  @media only screen and (max-width: 360px) {
    .header-game-1{
        height: 200px !important;
    }
    .caption {
        transform: none !important;
    }
    .caption > div > .header-game > h1{
        font-size: 18px;
    }
    .react-datepicker-wrapper {
        width: 86% !important;
    }
  
    .table-participant-game{
        width: 100% !important;
        overflow: auto;
    }
    .d-md-block {
        display: none !important;
    }
    .react-datepicker-wrapper {
        box-shadow: none !important;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        width: 84% !important;
    }
  
    .shape {
        background-size: 100% 250px !important;
        background-repeat: no-repeat !important;
    }
    .table-res {
        width: 100% !important;
    }
  
    .table-res>thead>tr>th:nth-child(2) {
        width: 50% !important;
    }
    
  }
  